
import { defineComponent, inject, Ref, ref, onMounted, computed, ComputedRef, watch } from 'vue';
import { heart, heartOutline, trashOutline } from 'ionicons/icons';
import {
  alertController,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonList,
  IonSpinner,
  IonText,
} from '@ionic/vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import { throttle } from 'lodash';

import VoteButton from '@/components/VoteButton.vue';
import getRoutesByUser, { UserRoute } from '@/common/api/route/getRoutesByUser';
import {checkAgainstUnauthorizedResponse} from "@/common/utils";
import {toFootholdName} from "@/common/api/footholdType";

export default defineComponent({
  name: 'UserRouteList',
  components: {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    IonList,
    IonSpinner,
    IonText,
    VoteButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const routes = ref<UserRoute[]>([]);
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getUserRole: () => ComputedRef<string> = inject('getUserRole', () => computed(() => ''));
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const profileUsername = computed(() => route.params.username as string);
    const isOwnself = computed(() => profileUsername.value === getUsername().value);
    const isAdmin = computed(() => getUserRole().value === 'admin');
    const totalUserUpvotes = computed(() =>
      routes.value.reduce((acc, curr) => acc + curr.voteCount, 0),
    );

    const isLoading = ref(false);

    const updateRoutes = throttle(async () => {
      routes.value = [];
      isLoading.value = true;

      try {
        const data = await getRoutesByUser(profileUsername.value);
        if (data.Message === 'Query routes by user success') {
          routes.value = data.Items;
        } else {
          throw new Error('Failed to get routes');
        }
      } catch (error:any) {
        checkAgainstUnauthorizedResponse(error);
        console.error(error);
      } finally {
        isLoading.value = false;
      }
    }, 1000);

    onMounted(updateRoutes);

    watch(profileUsername, () => {
      if (profileUsername.value) {
        updateRoutes();
      }
    });

    const handleRouteCardClick = (username: string, createdAt: string) => {
      router.push({
        name: 'ViewRoute',
        params: {
          username,
          createdAt,
        },
      });
    };

    const deleteRouteHandler = throttle(
      // The username in the params is the owner of the route being deleted
      async (route: UserRoute) => {
        
        const alert = await alertController.create({
          header: `Delete route '${route.routeName}'?`,
          message:
            'This action cannot be undone. <br/><br/> Once the route is deleted, you will not be able to restore it.',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Delete',
              cssClass: 'global-danger-text',
              handler: throttle(async () => {
                try {
                  const response = await axios.delete(
                    process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes/' + route.id,
                    {
                      headers: {
                        Authorization: `Bearer ${getAccessToken().value}`,
                      }
                    },
                  );
                  if (response.data.Message === 'Delete route success') {
                    if (routes.value) {
                      routes.value = routes.value.filter(
                        (r) => r != route,
                      );
                    }
                  }
                } catch (error:any) {
                  console.error(error);
                }
              }, 1000),
            },
          ],
        });
        return alert.present();
      },
      1000,
    );

    return {
      routes,
      totalUserUpvotes,
      handleRouteCardClick,
      deleteRouteHandler,
      heart,
      heartOutline,
      isAdmin,
      trashOutline,
      isOwnself,
      isLoading,
      toFootholdName
    };
  },
});
