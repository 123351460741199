
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonText,
  IonIcon,
  IonLabel,
  IonButton,
  alertController,
  toastController,
} from '@ionic/vue';
import axios from 'axios';
import { defineComponent, Ref, ref, inject, computed, ComputedRef } from 'vue';
import { personCircleOutline, flagOutline, shareSocialOutline } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import { throttle } from 'lodash';
import { getAlertController } from '@/common/reportUserAlert';
import UserRouteList from '@/components/UserRouteList.vue';
import { shareSocial } from '@/common/shareSocial';

export default defineComponent({
  name: 'UserRoutes',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonText,
    IonIcon,
    IonLabel,
    IonButton,
    UserRouteList,
  },
  setup() {
    const route = useRoute();
    const getLoggedIn: () => Ref<boolean> = inject('getLoggedIn', () => ref(false));
    const getAccessToken: () => Ref<string> = inject('getAccessToken', () => ref(''));
    const getUserRole: () => ComputedRef<string> = inject('getUserRole', () => computed(() => ''));
    // Note: Difference between profileUsername and username
    const getUsername: () => Ref<string> = inject('getUsername', () => ref(''));
    const isLoggedIn = getLoggedIn();
    const profileUsername = computed(() => route.params.username as string);
    const isOwnself = computed(() => getUsername().value === profileUsername.value);
    const isAdmin = getUserRole().value === 'admin';

    const reportUserHandler = throttle(async () => {
      const alert = await getAlertController(profileUsername.value, getAccessToken().value);
      return alert.present();
    }, 1000);

    /**
     * Only admins can see this
     */
    const disableUserHandler = throttle(async () => {
      const alert = await alertController.create({
        cssClass: 'global-wide',
        header: `Disable this user?`,
        message: 'Are you sure?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Yes',
            handler: async () => {
              try {
                await axios.post(
                  process.env.VUE_APP_USER_ENDPOINT_URL + '/v1/user/disable',
                  {
                    name: profileUsername.value,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${getAccessToken().value}`,
                    },
                  },
                );
                const toast = await toastController.create({
                  header: 'User has been successfully disabled',
                  position: 'bottom',
                  color: 'success',
                  duration: 3000,
                  buttons: [
                    {
                      text: 'Close',
                      role: 'cancel',
                    },
                  ],
                });
                toast.present();
              } catch (error:any) {
                console.error(error);
                const toast = await toastController.create({
                  header: 'Failed to disable user, please try again',
                  position: 'bottom',
                  color: 'danger',
                  duration: 3000,
                  buttons: [
                    {
                      text: 'Close',
                      role: 'cancel',
                    },
                  ],
                });
                toast.present();
              }
            },
          },
        ],
      });
      return alert.present();
    }, 1000);

    const sharePostHandler = async () => {
      await shareSocial(route, `Route by ${profileUsername.value}`);
    };

    return {
      profileUsername,
      personCircleOutline,
      isLoggedIn,
      isOwnself,
      flagOutline,
      shareSocialOutline,
      reportUserHandler,
      disableUserHandler,
      isAdmin,
      sharePostHandler,
    };
  },
});
