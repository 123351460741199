import axios from 'axios';
import Providers from '@/providers';
import cacheManager from 'cache-manager';
import { GymRoute } from './getRoutesByGym';
import {flatOutRoute} from "@/common/utils";

const memoryCache = cacheManager.caching({
  store: 'memory',
  max: 10, // Number of items in cache
  ttl: 1, // Seconds
});

const getRoutesByUserUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/routes';

const getRoutesByUser = async (username: string): Promise<ResponseData> => {
  const headers = {
    Authorization: `Bearer ${Providers.getAccessToken().value}`,
    accept: 'application/json'
  }
  const response = await axios.get(getRoutesByUserUrl, {
    headers,
    params: { 'user.username': username },
  });
  return {
    Message: Array.isArray(response.data) ? 'Query routes by user success' : 'Something went wrong'
    , Items: response.data.map(flatOutRoute)
  } as ResponseData;
};

// Caches in-memory, disappears on page refresh
const getRoutesByUserCached = async (username: string): Promise<ResponseData> => {
  return memoryCache.wrap(username, function () {
    return getRoutesByUser(username);
  });
};

interface ResponseData {
  Message: string;
  Items: UserRoute[];
}

interface UserRoute extends GymRoute {
  countryCode: string;
  gymName: string;
}

export default getRoutesByUserCached;
export { UserRoute };
